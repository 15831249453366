import React from "react";

export default function HeaderNameAvatar({name, key_name})
{
    //Hard Code
    return (
        <div className='Header-Avatar'>
            <div className='Name-StatusType'>
                <p className='Name'>{name} ({key_name})</p>
            </div>
        </div>
    )
}