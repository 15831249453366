import instance from "./api.config.js";

const AuthService = {
  login(username, password) {
    return instance.post("/login", { username, password });
  },

  register(username, password) {
    return instance.post("/signup", { username, password });
  },

  refresh() {
    return instance.get("/refresh");
  },

  me() {
    return instance.get("/users/me");
  },
  logout() {
    return instance.post("/logout");
  },
};

export default AuthService;
