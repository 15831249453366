import React, { useState, useEffect } from "react";
import Questions from "./Questions";
import Feedbacks from "./Feedbacks";
import Settings from "./Settings";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import authStoreInstance from "../store.js";
import MasterArea from "./Master-Area/Master-Area";

const Home = () => {
  const [activeTab, setActiveTab] = useState("reviews");
  const [username, setUsername] = useState(authStoreInstance.username);

  const renderContent = () => {
    switch (activeTab) {
      case "questions":
        return <Questions />;
      case "reviews":
        return <Feedbacks />;
      case "settings":
        return <Settings />;
      case "chat": // Adding case for chat
        return <MasterArea />;
      default:
        return null;
    }
  };

  const handleLogout = () => {
    authStoreInstance.logout(); // call logout from AuthStore
  };

  useEffect(() => {
    const getUsername = async () => {
      const result = await authStoreInstance.me();
      setUsername(result.data.username);
    };
    getUsername();
  }, []);

  return (
    <div>
      <Navbar bg="light" fixed="top">
        <Container>
          <Nav className="w-100" activeKey={activeTab} onSelect={setActiveTab}>
            <Nav.Item className="w-33 text-center">
              <Nav.Link eventKey="reviews">Отзывы</Nav.Link>
            </Nav.Item>
            <Nav.Item className="w-33 text-center">
              <Nav.Link eventKey="questions">Вопросы</Nav.Link>
            </Nav.Item>
            <Nav.Item className="w-33 text-center">
              <Nav.Link eventKey="settings">Настройки</Nav.Link>
            </Nav.Item>
            <Nav.Item className="w-33 text-center">
              <Nav.Link eventKey="chat">Чат с покупателем</Nav.Link>
            </Nav.Item>
          </Nav>
          <div className="d-flex align-items-center">
            <Navbar.Text className="mr-2 p-2">
              <strong>{username}</strong>
            </Navbar.Text>
            <Button variant="outline-danger" onClick={handleLogout}>
              Выйти
            </Button>
          </div>
        </Container>
      </Navbar>
      <Container
        style={{
          paddingTop: "70px", // установите достаточный отступ сверху
          minHeight: "100vh", // устанавливает минимальную высоту контейнера
          overflowY: "auto" // управляет прокруткой только внутри контейнера
        }}
      >
        {renderContent()}
      </Container>
    </div>
  );
};

export default Home;
