// Auth.js

import React, { useState } from "react";
import { Login, Register } from "./AuthFormComponents";
import { Nav, Container } from "react-bootstrap";

const Auth = () => {

  return (
    <Container
      className="d-flex flex-column justify-content-center align-items-center "
      style={{ height: "100vh" }}
    >
      <div
        style={{
          padding: "5px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: "20px",
          boxShadow: "0 0 20px rgba(0,0,0,.1)",
          width: "22rem",
        }}
      >
        <Login />
      </div>
    </Container>
  );
};

export default Auth;
