import React, { useState, useEffect, useRef } from "react";
import ChatArea from "../Chat-Area/Chat-Area";
import UserArea from "../User-Area/User-Area";
import ChatsService from "../../api.chats.js";

export default function MasterArea() {
    const [chats, setChats] = useState([]);
    const [messages, setMessages] = useState([]);
    const [currentOffset, setCurrentOffset] = useState(0);
    const [isLoading, setIsLoading] = useState(false); // New loading state
    const currentChatRef = useRef(null);
    const [currentChat, setCurrentChat] = useState(null);

    useEffect(() => {
        const loadChatsInterval = setInterval(loadChats, 3000);
        loadChats(); // Initial load
        return () => clearInterval(loadChatsInterval);
    }, []);

    const loadChats = async (offset = 0) => {
        if (isLoading) return; // Prevent loading if already in progress

        setIsLoading(true); // Set loading state to true

        try {
            const response = await ChatsService.getChats(offset);
            const newChats = response.data;
            if (offset === 0) {
                setChats(newChats);
            } else {
                setChats(prevChats => [...prevChats, ...newChats]);
            }
            console.log("Chats loaded:", newChats);

            if (!currentChatRef.current && newChats.length > 0) {
                setCurrentChat(newChats[0]);
                currentChatRef.current = newChats[0];
            } else if (currentChatRef.current) {
                const updatedCurrentChat = newChats.find(chat => chat.chatID === currentChatRef.current.chatID);
                if (!updatedCurrentChat && newChats.length > 0) {
                    setCurrentChat(newChats[0]);
                    currentChatRef.current = newChats[0];
                }
            }
        } catch (error) {
            console.error('Error loading chats:', error);
        } finally {
            setIsLoading(false); // Reset loading state
        }
    };

    const loadMoreChats = () => {
        const newOffset = currentOffset + 20; // Increment the offset by 20 (or any number based on your pagination)
        setCurrentOffset(newOffset);
        loadChats(newOffset);
    };

    const loadMessages = async (chatID) => {
        try {
            const response = await ChatsService.getEvents(0, 1000, chatID);
            setMessages(response.data);
            console.log("Messages loaded for chat:", chatID, response.data);
        } catch (error) {
            console.error('Error loading messages:', error);
        }
    };

    const openChat = (chatId) => {
        const chat = chats.find(c => c.chatID === chatId);
        if (chat) {
            console.log("Opening chat:", chat);
            setCurrentChat(chat);
            currentChatRef.current = chat;
            loadMessages(chat.chatID); // Load messages for the newly opened chat immediately
            console.log("Current chat set to:", chat);
        }
    };

    return (
        <div className='Master-Area'>
            <UserArea chats={chats} openChat={openChat} currentChat={currentChat} loadMoreChats={loadMoreChats} />
            {currentChatRef.current &&
                <ChatArea
                    messages={messages}
                    chat={currentChatRef.current}
                />}
        </div>
    );
}
