import React, { useState, useEffect } from "react";
import { Row, Col, Image, Button, Form, Collapse } from "react-bootstrap";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import QuestionsService from "../api.questions";

const QuestionCard = ({
  question,
  activeQuestion,
  setActiveQuestion,
  refreshQuestions,
}) => {
  const [answer, setAnswer] = useState("");
  const [showState, setShowState] = useState(question.state !== null);

  useEffect(() => {
    if (question.answer) {
      setAnswer(question.answer);
    }
  }, [question]);

  const handleAnswerChange = (event) => {
    setAnswer(event.target.value);
  };

  const handleAnswerSubmit = async () => {
    try {
      await QuestionsService.answerQuestion(
        question.id,
        answer,
        showState ? "wbRu" : null
      );
      console.log(
        `Submitted answer "${answer}" for question ID ${question.id}`
      );
      setAnswer("");
      setActiveQuestion(null);
      refreshQuestions();
    } catch (error) {
      console.error(
        `Error submitting answer for review ID ${question.id}:`,
        error
      );
    }
  };

  return (
    <React.Fragment>
      <Row className="gray-card pt-1 m-3">
        <Col
          xs={12}
          md={1}
          lg={1}
          className="p-2 justify-content-center d-flex"
        >
          <Image
            className="p-0"
            style={{
              width: "60px",
              height: "75px",
              borderRadius: "6px",
            }}
            src={question.product.photo}
          ></Image>
        </Col>
        <Col xs={12} lg={6} className="ProductInfo mt-2">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.wildberries.ru/catalog/${question.product.article}/detail.aspx`}
            className="text-primary"
            style={{ textDecoration: "none", fontSize: "18px" }}
          >
            {question.product.name} {question.product.brand}
          </a>
          <p className="QuestionText" style={{ wordWrap: "break-word" }}>
            {question.text}
          </p>
        </Col>
        <Col xs={12} md={2} lg={1} className="ProductOwner  center-content">
          {question.key.key_name}
        </Col>
        <Col xs={12} md={2} lg={1} className="ProductArticle  center-content">
          {question.product.article}
        </Col>
        <Col xs={12} md={2} lg={1} className="ProductCreatedAt  center-content">
          {`${new Date(question.created_at).toLocaleTimeString('ru-RU', {
            hour: '2-digit',
            minute: '2-digit'
          })} ${new Date(question.created_at).toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })}`}
        </Col>
        <Col xs={12} md={2} lg={2} className="QuestionDatee  center-content">
          <Button
            variant="primary"
            className="m-3"
            onClick={() =>
              setActiveQuestion(
                activeQuestion === question.id ? null : question.id
              )
            }
          >
            {"Ответ "}
            {activeQuestion === question.id ? (
              <FaChevronUp />
            ) : (
              <FaChevronDown />
            )}
          </Button>
        </Col>
        <Collapse in={activeQuestion === question.id}>
          <Form.Group className="mb-3">
            <Form.Control
              as="textarea"
              rows={3}
              value={answer}
              onChange={handleAnswerChange}
              readOnly={question.answer != null}
            />
            <Form.Check
              type="checkbox"
              className="mt-3"
              label="Опубликовать"
              checked={showState}
              disabled={question.state != null}
              onChange={(e) => setShowState(e.target.checked)}
            />
            {!question.answer && (
              <Button
                variant="primary"
                onClick={handleAnswerSubmit}
                className="mt-3"
              >
                Отправить
              </Button>
            )}
          </Form.Group>
        </Collapse>
      </Row>
    </React.Fragment>
  );
};

export default QuestionCard;
