import React from "react";
import UserSetInfo from "./User-Set-Info";

export default function MapInFakerData({ chats, openChat }) {
    return (
        <div className='Parent-Users'>
            {chats.map((chat, index) => (
                <div key={chat.chatID} onClick={() => openChat(chat.chatID)}>
                    <UserSetInfo
                        chat={chat}
                        openChat={openChat}
                    />
                </div>
            ))}
        </div>
    );
}
