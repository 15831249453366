import instance from "./api.config.js";

const SettingsService = {
  getSettings() {
    return instance.get(`/settings`);
  },

  createSetting(settingIn) {
    return instance.post(`/settings`, settingIn);
  },

  updateSetting(setting_id, settingIn) {
    return instance.put(`/settings/${setting_id}`, settingIn);
  },

  deleteSetting(setting_id) {
    return instance.delete(`/settings/${setting_id}`);
  },
};

export default SettingsService;
