import React, { useState, useEffect } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import SettingsService from "../api.settings.js";

const CHECKBOX_VALUES = ["1", "2", "3", "4", "5"];

const AutoAnswerSettings = () => {
  const [settings, setSettings] = useState({});
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const showSuccessMessage = (message, time) => {
    setSuccessMessage(message);
    setTimeout(() => {
      setSuccessMessage(null);
    }, time); // Уведомление исчезнет через 5 секунд
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await SettingsService.getSettings();
      const settingsFromServer = response.data.settings.reduce(
        (acc, setting) => {
          acc[setting.id] = setting;
          return acc;
        },
        {}
      );
      setSettings(settingsFromServer);
    } catch (err) {
      setError(err.response?.data?.detail || err.message);
    }
  };

  const handleAddSetting = async () => {
    try {
      const newSetting = { values: [], text: "" };
      const response = await SettingsService.createSetting(newSetting);
      setSettings((prevSettings) => ({
        ...prevSettings,
        [response.data.id]: response.data,
      }));
    } catch (err) {
      setError(err.response?.data?.detail || err.message);
    }
  };

  const handleRemoveSpecificSetting = async (settingId) => {
    try {
      await SettingsService.deleteSetting(settingId);
      setSettings((prevSettings) => {
        const newSettings = { ...prevSettings };
        delete newSettings[settingId];
        return newSettings;
      });
      showSuccessMessage("Настройка успешно удалена", 1000);
    } catch (err) {
      setError(err.response?.data?.detail || err.message);
    }
  };

  const handleSaveSettings = async (event) => {
    event.preventDefault();
    try {
      for (let settingId in settings) {
        await SettingsService.updateSetting(settingId, settings[settingId]);
      }
      showSuccessMessage("Настройки успешно сохранены!", 3000);
    } catch (err) {
      setError(err.response?.data?.detail || err.message);
    }
  };

  const handleResponseTextChange = (settingId, newText) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [settingId]: { ...prevSettings[settingId], text: newText },
    }));
  };

  const handleCheckboxChange = (settingId, checkboxValue) => {
    setSettings((prevSettings) => {
      const updatedValues = [...prevSettings[settingId].values];
      const checkboxIndex = updatedValues.indexOf(checkboxValue);
      if (checkboxIndex > -1) {
        updatedValues.splice(checkboxIndex, 1);
      } else {
        updatedValues.push(checkboxValue);
      }
      return {
        ...prevSettings,
        [settingId]: {
          ...prevSettings[settingId],
          values: updatedValues,
        },
      };
    });
  };

  const renderAutoAnswer = (settingId) => (
    <Form.Group className="mb-3 gray-card p-3" key={settingId}>
      <div className="d-flex">
        {CHECKBOX_VALUES.map((num) => (
          <Form.Check
            inline
            type="checkbox"
            label={num}
            key={num}
            checked={settings[settingId].values.includes(Number(num))}
            onChange={() => handleCheckboxChange(settingId, Number(num))}
          />
        ))}
      </div>
      <Form.Control
        className="mb-3 "
        as="textarea"
        rows={3}
        value={settings[settingId].text}
        onChange={(e) => handleResponseTextChange(settingId, e.target.value)}
      />
      <Button
        variant="secondary"
        onClick={() => handleRemoveSpecificSetting(settingId)}
      >
        Удалить
      </Button>
    </Form.Group>
  );

  return (
    <Form onSubmit={handleSaveSettings} className="d-flex flex-column">
      {error && <Alert variant="danger">{error}</Alert>}
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      {Object.keys(settings).map((settingId) => renderAutoAnswer(settingId))}
      <Button
        className="m-3"
        variant="secondary"
        onClick={handleAddSetting}
        disabled={Object.keys(settings).length >= 5}
      >
        Добавить
      </Button>
      <Button variant="primary" className="m-3" type="submit">
        Сохранить
      </Button>
    </Form>
  );
};

export default AutoAnswerSettings;
