import React from "react";
import { HiOutlineDocumentAdd, HiOutlineArrowRight } from "react-icons/hi";

export default function FooterForSendMessage({ keyBoard_SendInputSearchValue, handleChange, handleFileChange, inputValue, sendInputSearchValue, files }) {
    return (
        <div className='Footer-Input-Chat'>
            <div className='Input-TypeChat'>
                <div className='Attach-File'>
                    <input type="file" multiple onChange={handleFileChange} style={{ display: 'none' }} id="file-upload" />
                    <label htmlFor="file-upload" className='Icone'>
                        <HiOutlineDocumentAdd />
                    </label>
                </div>
                {files.length > 0 && (
                    <div className='Attached-Files'>
                        {files.map((file, index) => (
                            <div key={index} className='File-Item'>
                                {file.name}
                            </div>
                        ))}
                    </div>
                )}
                <input
                    onKeyPress={keyBoard_SendInputSearchValue}
                    onChange={handleChange}
                    value={inputValue}
                    placeholder='Ваше сообщение'
                    className='Input-Type'
                />
                <button className='Search-Button' onClick={sendInputSearchValue}>
                    <HiOutlineArrowRight className={'Icone'} />
                </button>
            </div>
        </div>
    );
}
