import instance from "./api.config.js";
import axios from "axios";

const ChatsService = {
    getChats(skip = 0, limit = 100) {
        return instance.get(`/chats?skip=${skip}&limit=${limit}`);
    },

    getEvents(skip = 0, limit = 1000, chat_id) {
        return instance.get(`/chats/${chat_id}/events?skip=${skip}&limit=${limit}`);
    },

    sendMessage: async (replySign, message, api_key, files = []) => {
        const formData = new FormData();
        formData.append('replySign', replySign);
        formData.append('message', message);
        files.forEach((file, index) => {
            formData.append(`file`, file);
        });

        try {
            const response = await axios.post(`https://buyer-chat-api.wildberries.ru/api/v1/seller/message`, formData, {
                headers: {
                    'Authorization': `Bearer ${api_key}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error sending message:', error);
            throw error;
        }
    }
};

export default ChatsService;