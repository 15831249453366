import React, { Component } from "react";
import { animations } from 'react-animation';
import FooterForSendMessage from "./Footer-For-Send-Message";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { HiOutlineClock } from 'react-icons/hi';

const InterStyleChat = {
    animation: animations.fadeIn
}

export default class LeftRightChat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
            files: []  // State to store selected files
        };
        this.messagesEndRef = React.createRef();  // Ref for scrolling
    }

    componentDidMount() {
        this.scrollToBottom();
    }

    componentDidUpdate(prevProps) {
        if (this.props.messages.length !== prevProps.messages.length || this.props.chat.chatID !== prevProps.chat.chatID || this.props.tempMessages.length !== prevProps.tempMessages.length) {
            this.scrollToBottom();
        }

        // Reset input and files when chat changes
        if (this.props.chat.chatID !== prevProps.chat.chatID) {
            this.setState({ inputValue: '', files: [] });
        }
    }

    scrollToBottom = () => {
        if (this.messagesEndRef.current) {
            this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }

    handleChange = (e) => {
        this.setState({ inputValue: e.target.value });
    }

    handleFileChange = (e) => {
        this.setState({ files: Array.from(e.target.files) });
    }

    sendInputSearchValue = async () => {
        const { inputValue, files } = this.state;
        if (inputValue.trim() || files.length > 0) {
            await this.props.SendMessage(inputValue, files);
            this.setState({ inputValue: '', files: [] });
            this.scrollToBottom();  // Scroll to bottom after sending a message
        }
    }

    keyBoard_SendInputSearchValue = async (e) => {
        if (e.key === 'Enter') {
            await this.sendInputSearchValue();
        }
    }

    formatTimestamp = (timestamp) => {
        const date = new Date(timestamp * 1000);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${day}-${month}-${year} ${hours}:${minutes}`;
    }

    render() {
        const allMessages = [...this.props.messages, ...this.props.tempMessages];
        const { inputValue, files } = this.state;

        return (
            <>
                <div className="messages-list" style={{ flex: 1, overflowY: 'auto' }}>
                    {allMessages.map((msg, index) => (
                        <div
                            style={InterStyleChat}
                            key={index}
                            className={`${msg.sender === 'seller' ? 'Body-Chat-Right' : 'Body-Chat-Left'} ${msg.isTemporary ? 'temporary-message' : ''}`}>
                            <div className='Send-Message'>
                                <div className={`Text-Area ${msg.eventType === 'refund' ? 'Refund-Message' : ''}`}>
                                    {msg.eventType === 'refund' && (
                                        <div className='Refund-Notice'>
                                            Возврат
                                        </div>
                                    )}
                                    {msg.text && (
                                        <div className='Sentence'>
                                            {msg.text}
                                        </div>
                                    )}
                                    {msg.attachments && msg.attachments.images && (
                                        <PhotoProvider>
                                            {msg.attachments.images.map((image, idx) => (
                                                <PhotoView key={idx} src={image.url}>
                                                    <div className='Image-Area'>
                                                        <img src={image.url} alt="attachment" className='Chat-Image' />
                                                    </div>
                                                </PhotoView>
                                            ))}
                                        </PhotoProvider>
                                    )}
                                    {msg.attachments && msg.attachments.files && msg.attachments.files.map((file, idx) => (
                                        <div key={idx} className='File-Area'>
                                            <a href={file.url} download={file.name} target="_blank" rel="noopener noreferrer" className='Chat-File'>
                                                {file.name}
                                            </a>
                                            <div className='File-Info'>
                                                {file.contentType} - {(file.size / 1024).toFixed(2)} KB
                                            </div>
                                        </div>
                                    ))}
                                    {msg.attachments && msg.attachments.goodCard && (
                                        <div className='GoodCard-Area'>
                                            <div className='GoodCard-Info'>
                                                <strong>Заказ</strong>
                                                <p>Дата: {new Date(msg.attachments.goodCard.date).toLocaleString()}</p>
                                                <p>Требуется возврат: {msg.attachments.goodCard.needRefund ? "Да" : "Нет"}</p>
                                                <p>Артикул: {msg.attachments.goodCard.nmID}</p>
                                                <p>Цена: {(msg.attachments.goodCard.price / 100).toFixed(2)} {msg.attachments.goodCard.priceCurrency}</p>
                                                <p>Размер: {msg.attachments.goodCard.size}</p>
                                                <p>Статус: {msg.attachments.goodCard.statusID}</p>
                                            </div>
                                        </div>
                                    )}
                                    <div className='Time'>
                                        {msg.isTemporary && <HiOutlineClock className='temporary-icon' />}
                                        {this.formatTimestamp(msg.timestamp)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div ref={this.messagesEndRef} /> {/* Container for scrolling */}
                </div>
                <FooterForSendMessage
                    keyBoard_SendInputSearchValue={this.keyBoard_SendInputSearchValue}
                    handleChange={this.handleChange}
                    handleFileChange={this.handleFileChange}
                    inputValue={inputValue}
                    sendInputSearchValue={this.sendInputSearchValue}
                    files={files}
                />
            </>
        );
    }
}
