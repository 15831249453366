import instance from "./api.config.js";

const FeedbacksService = {
  getUnansweredFeedbacks(page = 1) {
    return instance.get(`/feedbacks/unanswered?page=${page}`);
  },

  getAnsweredFeedbacks(page = 1) {
    return instance.get(`/feedbacks/answered?page=${page}`);
  },

  answerFeedback(feedback_id, answer) {
    return instance.put(`/feedbacks/${feedback_id}`, { answer: answer });
  },
};

export default FeedbacksService;
