import React from "react";
import HeaderNameAvatar from "./Header-Name&Avatar";

export default function HeaderChatArea({chat_name, key_name})
{
    return (
        <div className='Header-Chat'>
            <HeaderNameAvatar name={chat_name} key_name={key_name}/>

        </div>
    )
}