import AuthForm from "./AuthForm";
import authStoreInstance from "../store";

const Login = () => {
  return (
    <AuthForm
      authAction={authStoreInstance.login}
      redirectPath="/home"
      buttonText="Войти"
      successMessageText="Успешный вход!"
      errorMessageMap={{
        404: "Такого аккуанта не существует",
        401: "Неверный логин или пароль",
        422: "Проверьте введенные данные",
      }}
    />
  );
};


export { Login };
