import React, { useState, useEffect, useCallback } from "react";
import { Tabs, Tab, Pagination, Container, ListGroup } from "react-bootstrap";
import FeedbacksService from "../api.feedbacks";
import FeedbackCard from "./FeedbackCard";

const ITEMS_PER_PAGE = 25;

const Feedbacks = () => {
  const [key, setKey] = useState("feedbacksWithoutAnswer");
  const [page, setPage] = useState(1);
  const [activeFeedback, setActiveFeedback] = useState(null);

  return (
    <Container>
      <Tabs
        id="feedbacks-tabs"
        activeKey={key}
        onSelect={(k) => {
          setKey(k);
          setPage(1);
          setActiveFeedback(null); // reset active feedback when switching tabs
        }}
      >
        <Tab eventKey="feedbacksWithoutAnswer" title="Отзывы без ответов">
          <FeedbacksTable
            feedbackFetcher={FeedbacksService.getUnansweredFeedbacks}
            page={page}
            setPage={setPage}
            activeFeedback={activeFeedback}
            setActiveFeedback={setActiveFeedback}
          />
        </Tab>
        <Tab eventKey="archiveFeedbacks" title="Архив">
          <FeedbacksTable
            feedbackFetcher={FeedbacksService.getAnsweredFeedbacks}
            page={page}
            setPage={setPage}
            activeFeedback={activeFeedback}
            setActiveFeedback={setActiveFeedback}
          />
        </Tab>
      </Tabs>
    </Container>
  );
};

const FeedbacksTable = ({
  feedbackFetcher,
  page,
  setPage,
  activeFeedback,
  setActiveFeedback,
}) => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [totalFeedbacks, setTotalFeedbacks] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchFeedbacks = useCallback(async () => {
    const result = await feedbackFetcher(currentPage);
    setFeedbacks(result.data.feedbacks);
    setTotalFeedbacks(result.data.total_count);
  }, [currentPage, feedbackFetcher]);

  useEffect(() => {
    fetchFeedbacks();
    const interval = setInterval(fetchFeedbacks, 30000);
    return () => clearInterval(interval);
  }, [fetchFeedbacks]);

  const totalPages = Math.ceil(totalFeedbacks / ITEMS_PER_PAGE);

  const handlePageChange = useCallback(
    (pageNumber) => {
      if (pageNumber < 1 || pageNumber > totalPages) {
        return; // prevent navigating to non-existent pages
      }
      setCurrentPage(pageNumber);
    },
    [totalPages]
  );

  return (
    <>
      <ListGroup className="feedback-table">
        {feedbacks.length > 0 ? (
          feedbacks.map((feedback) => (
            <FeedbackCard
              key={feedback.id}
              feedback={feedback}
              activeFeedback={activeFeedback}
              setActiveFeedback={setActiveFeedback}
              refreshFeedbacks={fetchFeedbacks}
            />
          ))
        ) : (
          <p>Отзывов нет</p>
        )}
      </ListGroup>
      <Pagination>
        {/* Показать первую страницу только если текущая страница больше 6 */}
        {currentPage > 6 && (
          <Pagination.Item
            key={1}
            active={1 === currentPage}
            onClick={() => handlePageChange(1)}
          >
            1
          </Pagination.Item>
        )}

        {/* Если текущая страница больше 6, показываем точки после первой страницы */}
        {currentPage > 6 && <Pagination.Item disabled>...</Pagination.Item>}

        {/* Отображаем страницы в зависимости от текущей */}
        {[...Array(totalPages).keys()].map((page) => {
          if (
            (currentPage <= 10 && page < 10) ||
            (currentPage > 6 &&
              page >= currentPage - 5 &&
              page < currentPage + 4)
          ) {
            return (
              <Pagination.Item
                key={page + 1}
                active={page + 1 === currentPage}
                onClick={() => handlePageChange(page + 1)}
              >
                {page + 1}
              </Pagination.Item>
            );
          }
          return null; // Иначе не показываем
        })}

        {/* Если общее количество страниц больше текущей + 5, показываем точки перед последней страницей */}
        {totalPages > currentPage + 5 && (
          <Pagination.Item disabled>...</Pagination.Item>
        )}

        {/* Показать последнюю страницу только если общее количество страниц больше текущей на 5 или более */}
        {totalPages > currentPage + 5 && (
          <Pagination.Item
            key={totalPages}
            active={totalPages === currentPage}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </Pagination.Item>
        )}

        {/* Кнопка "Далее" */}
        {totalPages > 1 && currentPage < totalPages && (
          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
        )}
      </Pagination>
    </>
  );
};

export default Feedbacks;
