import React, { useState, useEffect, useRef } from "react";
import HeaderChatArea from "./Header-Chat-Area";
import ScrollBlock from "./Scroll-Block";
import ChatsService from "../../api.chats.js";

export default function ChatArea({ chat }) {
    const RefBodyChat = useRef();
    const RefChatArea = useRef();
    const [messages, setMessages] = useState([]);
    const [tempMessages, setTempMessages] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        loadMessages();
        const interval = setInterval(loadMessages, 10000); // Update messages every 10 seconds
        return () => clearInterval(interval);
    }, [chat]);

    const loadMessages = async () => {
        try {
            const response = await ChatsService.getEvents(0, 1000, chat.chatID);
            setMessages(response.data);
            setTempMessages([]); // Clear temporary messages when messages are updated
        } catch (error) {
            console.error('Error loading messages:', error);
        }
    };

    const handleScroll = () => {
        if (RefBodyChat.current && RefBodyChat.current.scrollTop === 0) {
            // Load more messages when user scrolls to the top
        }
    };

    const SendMessage = async (message, files) => {
        const tempId = `temp-${Date.now()}`;
        const tempMessage = {
            eventID: tempId,
            chatID: chat.chatID,
            eventType: 'message',
            sender: 'seller',
            text: message,
            timestamp: Date.now() / 1000,
            attachments: { files: files.map(file => ({ name: file.name })) },
            isTemporary: true
        };

        setTempMessages(prevTempMessages => [...prevTempMessages, tempMessage]);

        try {
            await ChatsService.sendMessage(chat.replySign, message, chat.apiKey, files);
            setErrorMessage('');
        } catch (error) {
            console.error('Error sending message:', error);
            const serverErrorMessage = `Ошибка при отправке сообщения: ${error.response?.data?.error}` || 'Ошибка при отправке сообщения. Попробуйте позднее.';
            setErrorMessage(serverErrorMessage);
            setTempMessages(prevTempMessages => prevTempMessages.filter(msg => msg.eventID !== tempId));
        }
    };

    const dismissError = () => {
        setErrorMessage('');
    };

    return (
        <div ref={RefChatArea} className='Chat-Area'>
            <HeaderChatArea chat_name={chat.clientName} key_name={chat.apiName} />
            <ScrollBlock
                handleScroll={handleScroll}
                RefBodyChat={RefBodyChat}
                messages={messages}
                tempMessages={tempMessages}
                SendMessage={SendMessage}
                chat={chat}
                errorMessage={errorMessage}
                dismissError={dismissError}
            />
        </div>
    );
}
