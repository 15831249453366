import React, { useRef } from "react";
import MapInFakerData from "./Map-In-Faker-Data";

export default function UserArea({ chats, openChat, currentChat, loadMoreChats }) {
    const UserAreaRef = useRef();

    const handleScroll = () => {
        if (UserAreaRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = UserAreaRef.current;
            if (scrollTop + clientHeight >= scrollHeight) {
                loadMoreChats();
            }
        }
    };

    return (
        <div ref={UserAreaRef} className='User-Area' onScroll={handleScroll}>
            <MapInFakerData chats={chats} openChat={openChat} />
        </div>
    );
}
