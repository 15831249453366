import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import AutoAnswerSettings from "./AutoAnswerSettings";
import ApiKeySettings from "./ApiKeySettings";

const Settings = () => {
  return (
    <Container fluid>
      <Row>
        <Col md={6}>
          <ApiKeySettings />
        </Col>
        <Col md={6}>
          <AutoAnswerSettings />
        </Col>
      </Row>
    </Container>
  );
};

export default Settings;
