import instance from "./api.config.js";

const QuestionsService = {
  getUnansweredQuestions(page = 1) {
    return instance.get(`/questions/unanswered?page=${page}`);
  },

  getAnsweredQuestions(page = 1) {
    return instance.get(`/questions/answered?page=${page}`);
  },

  answerQuestion(question_id, answer, state) {
    return instance.put(`/questions/${question_id}`, {
      answer: answer,
      state: state,
    });
  },
};

export default QuestionsService;
