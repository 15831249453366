// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gray-card {
  border-radius: 6px;
  border: none;
  background: #f4f4f8;
}
.container {
  max-width: 90% !important;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".center-content {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.gray-card {\n  border-radius: 6px;\n  border: none;\n  background: #f4f4f8;\n}\n.container {\n  max-width: 90% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
