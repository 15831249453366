import React from "react";
import Informations from "./Informations";

export default function UserSetInfo({ chat, openChat }) {
    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp * 1000);
        const today = new Date();
        
        const isToday = (date.getDate() === today.getDate() &&
                         date.getMonth() === today.getMonth() &&
                         date.getFullYear() === today.getFullYear());

        if (isToday) {
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            return `${hours}:${minutes}`;
        } else {
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();
            return `${day}.${month}.${year}`;
        }
    };

    return (
        <div className='Users'>
            <Informations
                Name={chat.clientName}
                Time={formatTimestamp(chat.timestamp)}
                Sentence={chat.text}
            />
        </div>
    );
}
