import instance from "./api.config.js";

const KeysService = {
  getApikeys() {
    return instance.get(`/apikeys`);
  },

  createApikey(apiKeyIn) {
    return instance.post(`/apikeys`, apiKeyIn);
  },

  updateApikey(apikey_id, apiKeyIn) {
    return instance.put(`/apikeys/${apikey_id}`, apiKeyIn);
  },

  deleteApikey(apikey_id) {
    return instance.delete(`/apikeys/${apikey_id}`);
  },
};

export default KeysService;
