// AuthForm.js

import React, { useState } from "react";
import { Form, Button, Alert, FloatingLabel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const AuthForm = ({
  authAction,
  redirectPath,
  buttonText,
  errorMessageMap,
  successMessageText,
}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const navigate = useNavigate();

  const validatePassword = (password) => {
    return password.length >= 5 && password.length <= 24;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    if (!validatePassword(password)) {
      setErrorMessage("Пароль должен быть длиной от 5 до 24 символов");
      return;
    }

    try {
      const result = await authAction(username, password);
      navigate(redirectPath);
      if (result.status === 201 || result.status === 200) {
        setSuccessMessage(successMessageText);
      }
    } catch (err) {
      if (err.response && err.response.status in errorMessageMap) {
        setErrorMessage(errorMessageMap[err.response.status]);
      } else {
        setErrorMessage("Ошибка сервера");
      }
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      className="d-flex flex-column justify-content-center align-items-center m-3"
    >
      <FloatingLabel controlId="floatingInput" label="Логин" className="m-3">
        <Form.Control
          type="text"
          placeholder="Логин"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          autoComplete="username"
        />
      </FloatingLabel>

      <FloatingLabel
        controlId="floatingPassword"
        label="Пароль"
        className="m-3"
      >
        <Form.Control
          type="password"
          placeholder="Пароль"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoComplete={
            buttonText === "Войти" ? "current-password" : "new-password"
          }
        />
      </FloatingLabel>

      <Button
        variant="primary"
        type="submit"
        className="m-3"
        // style={{ width: "50%" }}
      >
        {buttonText}
      </Button>

      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
    </Form>
  );
};

export default AuthForm;
