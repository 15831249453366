import React, { useState, useEffect, useCallback } from "react";
import { Tabs, Tab, Pagination, Container, ListGroup } from "react-bootstrap";
import QuestionsService from "../api.questions";
import QuestionCard from "./QuestionCard";

const ITEMS_PER_PAGE = 25;

const Questions = () => {
  const [key, setKey] = useState("questionsWithoutAnswer");
  const [page, setPage] = useState(1);
  const [activeQuestion, setActiveQuestion] = useState(null);

  return (
    <Container>
      <Tabs
        id="questions-tabs"
        activeKey={key}
        onSelect={(k) => {
          setKey(k);
          setPage(1);
          setActiveQuestion(null); // reset active question when switching tabs
        }}
      >
        <Tab eventKey="questionsWithoutAnswer" title="Вопросы без ответов">
          <QuestionsTable
            questionFetcher={QuestionsService.getUnansweredQuestions}
            page={page}
            setPage={setPage}
            activeQuestion={activeQuestion}
            setActiveQuestion={setActiveQuestion}
          />
        </Tab>
        <Tab eventKey="archiveQuestions" title="Архив">
          <QuestionsTable
            questionFetcher={QuestionsService.getAnsweredQuestions}
            page={page}
            setPage={setPage}
            activeQuestion={activeQuestion}
            setActiveQuestion={setActiveQuestion}
          />
        </Tab>
      </Tabs>
    </Container>
  );
};

const QuestionsTable = ({
  questionFetcher,
  page,
  setPage,
  activeQuestion,
  setActiveQuestion,
}) => {
  const [questions, setQuestions] = useState([]);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchQuestions = useCallback(async () => {
    const result = await questionFetcher(currentPage);
    setQuestions(result.data.questions);
    setTotalQuestions(result.data.total_count);
  }, [currentPage, questionFetcher]);

  useEffect(() => {
    fetchQuestions();
    const interval = setInterval(fetchQuestions, 30000);
    return () => clearInterval(interval);
  }, [fetchQuestions, currentPage]);

  const totalPages = Math.ceil(totalQuestions / ITEMS_PER_PAGE);

  const handlePageChange = useCallback(
    (pageNumber) => {
      if (pageNumber < 1 || pageNumber > totalPages) {
        return; // prevent navigating to non-existent pages
      }
      setCurrentPage(pageNumber);
    },
    [totalPages]
  );

  return (
    <>
      <ListGroup className="question-table">
        {questions.length > 0 ? (
          questions.map((question) => (
            <QuestionCard
              key={question.id}
              question={question}
              activeQuestion={activeQuestion}
              setActiveQuestion={setActiveQuestion}
              refreshQuestions={fetchQuestions}
            />
          ))
        ) : (
          <p>Отзывов нет</p>
        )}
      </ListGroup>
      <Pagination>
        {/* Показать первую страницу только если текущая страница больше 6 */}
        {currentPage > 6 && (
          <Pagination.Item
            key={1}
            active={1 === currentPage}
            onClick={() => handlePageChange(1)}
          >
            1
          </Pagination.Item>
        )}

        {/* Если текущая страница больше 6, показываем точки после первой страницы */}
        {currentPage > 6 && <Pagination.Item disabled>...</Pagination.Item>}

        {/* Отображаем страницы в зависимости от текущей */}
        {[...Array(totalPages).keys()].map((page) => {
          if (
            (currentPage <= 10 && page < 10) ||
            (currentPage > 6 &&
              page >= currentPage - 5 &&
              page < currentPage + 4)
          ) {
            return (
              <Pagination.Item
                key={page + 1}
                active={page + 1 === currentPage}
                onClick={() => handlePageChange(page + 1)}
              >
                {page + 1}
              </Pagination.Item>
            );
          }
          return null; // Иначе не показываем
        })}

        {/* Если общее количество страниц больше текущей + 5, показываем точки перед последней страницей */}
        {totalPages > currentPage + 5 && (
          <Pagination.Item disabled>...</Pagination.Item>
        )}

        {/* Показать последнюю страницу только если общее количество страниц больше текущей на 5 или более */}
        {totalPages > currentPage + 5 && (
          <Pagination.Item
            key={totalPages}
            active={totalPages === currentPage}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </Pagination.Item>
        )}

        {/* Кнопка "Далее" */}
        {totalPages > 1 && currentPage < totalPages && (
          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
        )}
      </Pagination>
    </>
  );
};

export default Questions;
