import React, { useState, useEffect } from "react";
import { Row, Col, Image, Button, Form, Collapse } from "react-bootstrap";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import Star from "./Star";
import FeedbacksService from "../api.feedbacks";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

const FeedbackCard = ({
  feedback,
  activeFeedback,
  setActiveFeedback,
  refreshFeedbacks,
}) => {
  const [answer, setAnswer] = useState("");
  useEffect(() => {
    if (feedback.answer) {
      setAnswer(feedback.answer);
    }
  }, [feedback]);

  const handleAnswerChange = (event) => {
    setAnswer(event.target.value);
  };

  const handleAnswerSubmit = async () => {
    try {
      await FeedbacksService.answerFeedback(feedback.id, answer);
      console.log(
        `Submitted answer "${answer}" for feedback ID ${feedback.id}`
      );
      setAnswer("");
      setActiveFeedback(null);
      refreshFeedbacks();
    } catch (error) {
      console.error(
        `Error submitting answer for review ID ${feedback.id}:`,
        error
      );
    }
  };

  return (
    <React.Fragment>
      <Row className="gray-card pt-1 m-3">
        <Col
          xs={12}
          md={1}
          lg={1}
          className="p-2 justify-content-center d-flex"
        >
          <Image
            className="p-0"
            style={{
              width: "60px",
              height: "75px",
              borderRadius: "6px",
            }}
            src={feedback.product.photo}
          ></Image>
        </Col>
        <Col xs={12} lg={5} className="ProductInfo mt-2">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.wildberries.ru/catalog/${feedback.product.article}/detail.aspx`}
            className="text-primary"
            style={{ textDecoration: "none", fontSize: "18px" }}
          >
            {feedback.product.name} {feedback.product.brand}
          </a>
          <p className="FeedbackText" style={{ wordWrap: "break-word" }}>
            {feedback.text}
          </p>
        </Col>
        <Col xs={12} md={2} lg={1} className="FeedbackStart center-content">
          {[...Array(feedback.rating)].map((_, i) => (
            <Star key={i} fill="#FF773C" />
          ))}
          {[...Array(5 - feedback.rating)].map((_, i) => (
            <Star key={i + feedback.rating} fill="#d1cfd7" />
          ))}
        </Col>
        <Col xs={12} md={2} lg={1} className="ProductOwner  center-content">
          {feedback.key.key_name}
        </Col>
        <Col xs={12} md={2} lg={1} className="ProductArticle  center-content">
          {feedback.product.article}
        </Col>
        <Col xs={12} md={2} lg={1} className="ProductCreatedAt  center-content">
          {`${new Date(feedback.created_at).toLocaleTimeString('ru-RU', {
            hour: '2-digit',
            minute: '2-digit'
          })} ${new Date(feedback.created_at).toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })}`}
        </Col>
        <Col xs={12} md={2} lg={2} className="FeedbackDatee  center-content">
          <Button
            variant="primary"
            className="m-3"
            onClick={() =>
              setActiveFeedback(
                activeFeedback === feedback.id ? null : feedback.id
              )
            }
          >
            {"Ответ "}
            {activeFeedback === feedback.id ? (
              <FaChevronUp />
            ) : (
              <FaChevronDown />
            )}
          </Button>
        </Col>
        <PhotoProvider>
          <div className="photo-previews">
            {feedback.photos && feedback.photos.map((photo, index) => (
              <PhotoView key={index} src={photo}>
                <img
                  style={{ width: 80, height: 100, borderRadius: 6, margin: "0px 10px 16px 10px" }}
                  src={photo}
                //style={{ maxWidth: "100px", maxHeight: "100px", margin: "5px", cursor: 'pointer' }}
                />
              </PhotoView>
            ))}
          </div>
        </PhotoProvider>
        <Collapse in={activeFeedback === feedback.id}>
          <Form.Group className="mb-3">
            <Form.Control
              as="textarea"
              rows={3}
              value={answer}
              onChange={handleAnswerChange}
              readOnly={feedback.answer != null} // set the field as read-only if feedback.answer is not null
            />
            {!feedback.answer && ( // do not display the button if feedback.answer is not null
              <Button
                variant="primary"
                onClick={handleAnswerSubmit}
                className="mt-3"
              >
                Отправить
              </Button>
            )}
          </Form.Group>
        </Collapse>
      </Row>
    </React.Fragment>
  );
};

export default FeedbackCard;
