import React from "react";

const Star = ({ fill }) => (
  <div className="star" style={{ marginRight: "2px" }}>
    <svg
      fill="none"
      height="16"
      viewBox="0 1 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M6.95366 1.67096C7.36142 0.732403 8.63858 0.732403 9.04634 1.67096L10.5179 5.05817C10.6851 5.443 11.034 5.70759 11.4364 5.75463L14.9776 6.16869C15.9588 6.28342 16.3535 7.55119 15.6243 8.24597L12.9925 10.7534C12.6935 11.0383 12.5603 11.4664 12.6417 11.8803L13.3587 15.5235C13.5574 16.5329 12.5241 17.3164 11.6657 16.8073L8.56762 14.9698C8.21564 14.761 7.78436 14.761 7.43238 14.9698L4.33429 16.8073C3.47585 17.3164 2.4426 16.5329 2.64128 15.5235L3.35828 11.8803C3.43974 11.4664 3.30647 11.0383 3.00747 10.7534L0.375744 8.24597C-0.353472 7.55119 0.0411929 6.28342 1.02242 6.16869L4.56363 5.75463C4.96596 5.70759 5.31488 5.443 5.48207 5.05817L6.95366 1.67096Z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  </div>
);

export default Star;
