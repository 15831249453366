import { makeAutoObservable } from "mobx";
import AuthService from "./api.auth.js";

class AuthStore {
  isAuth = false;
  isAuthInProgress = false;
  isLoadingAuth = true;
  username = "";

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async login(username, password) {
    this.isAuthInProgress = true;
    try {
      const resp = await AuthService.login(username, password);
      localStorage.setItem("token", resp.data.access_token);
      this.isAuth = true;
      const userInfo = await this.me();
      this.username = userInfo.data.username;
    } catch (err) {
      throw err;
    } finally {
      this.isAuthInProgress = false;
    }
  }
  async checkAuth() {
    this.isLoadingAuth = true;
    try {
      const resp = await AuthService.refresh();
      localStorage.setItem("token", resp.data.access_token);
      this.isAuth = true;
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoadingAuth = false;
    }
  }

  async register(username, password) {
    this.isAuthInProgress = true;
    try {
      return await AuthService.register(username, password);
    } catch (err) {
      throw err;
    } finally {
      this.isAuthInProgress = false;
    }
  }

  async logout() {
    this.isAuthInProgress = true;
    try {
      this.isAuth = false;
      await AuthService.logout();
      localStorage.removeItem("token");
    } catch (err) {
      throw err;
    } finally {
      this.isAuthInProgress = false;
    }
  }

  async me() {
    try {
      console.log("me");
      const resp = await AuthService.me();
      this.username = resp.data.username;
      return resp;
    } catch (err) {
      console.log(err);
    }
  }
}

const authStoreInstance = new AuthStore();
export default authStoreInstance;
