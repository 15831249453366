import axios from "axios";

const instance = axios.create({
  withCredentials: true,
  baseURL: "https://akae-corp.ru/api/",
});

// Перехватчик запроса
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Перехватчик ответа
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Проверяем, что ошибка связана с аутентификацией и что ранее не было попыток повторного запроса
    if (
      (error.response.status === 401 || error.response.status === 403) &&
      !originalRequest._retry &&
      originalRequest.url !== "/refresh"
    ) {
      originalRequest._retry = true;

      try {
        const resp = await instance.get("/refresh");
        if (resp.status === 200) {
          localStorage.setItem("token", resp.data.access_token);
          originalRequest.headers.Authorization = `Bearer ${resp.data.access_token}`;
          return instance(originalRequest); // Повторно выполняем первоначальный запрос
        }
      } catch (refreshError) {
        console.error("Ошибка при обновлении токена", refreshError);
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
