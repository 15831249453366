// App.js

import React, { useEffect } from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import authStoreInstance from "./store.js";
import PrivateRoute from "./privateRoute.js";
import Home from "./components/Home.js";
import Auth from "./components/Auth.js"; // Import the new Auth component
import "./App.css";
import './Css/Variabels.css'

const App = observer(() => {
  useEffect(() => {
    authStoreInstance.checkAuth();
  }, []);

  if (authStoreInstance.isLoadingAuth) {
    return <div>Loading...</div>; // Или любой другой компонент "загрузка"
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/home"
          element={<PrivateRoute isAuth={authStoreInstance.isAuth} />}
        >
          <Route path="" element={<Home />} />
        </Route>
        <Route path="/login" element={<Auth />} />
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </BrowserRouter>
  );
});

export default App;
