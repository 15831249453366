import React, { useState, useEffect } from "react";
import { Form, Button, Table, Alert } from "react-bootstrap";
import KeysService from "../api.keys";

const ApiKeySettings = () => {
  const [apikeys, setApikeys] = useState([]);
  const [apiKeyName, setApiKeyName] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [selectedApikey, setSelectedApikey] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchApikeys();
  }, []);

  const fetchApikeys = async () => {
    try {
      const response = await KeysService.getApikeys();
      setApikeys(response.data.apikeys);
    } catch (err) {
      setError(err.response?.data?.detail || err.message);
    }
  };

  const handleAddApikey = async (event) => {
    event.preventDefault();
    try {
      const response = await KeysService.createApikey({
        key_name: apiKeyName,
        key_value: apiKey,
        isActive: isActive,
      });
      setApikeys([...apikeys, response.data]);
      setApiKeyName("");
      setApiKey("");
      setIsActive(true);
    } catch (err) {
      setError(err.response?.data?.detail || err.message);
    }
  };

  const handleUpdateApikey = async (event) => {
    event.preventDefault();
    try {
      await KeysService.updateApikey(selectedApikey, {
        key_name: apiKeyName,
        key_value: apiKey,
        isActive: isActive,
      });
      setApiKeyName("");
      setApiKey("");
      setIsActive(true);
      setSelectedApikey(null);
      fetchApikeys();
    } catch (error) {
      setError(error.response.data.detail);
    }
  };

  const handleCancelEdit = () => {
    setSelectedApikey(null);
    setApiKeyName("");
    setApiKey("");
    setIsActive(true);
  };

  const handleSelectApikey = (apikey) => {
    setSelectedApikey(apikey.id);
    setApiKeyName(apikey.key_name);
    setApiKey(apikey.key_value);
    setIsActive(apikey.isActive);
  };

  const handleDeleteApikey = async (apikeyId) => {
    try {
      await KeysService.deleteApikey(apikeyId);
      setApikeys(apikeys.filter((apikey) => apikey.id !== apikeyId));
    } catch (error) {
      setError(error.response.data.detail);
    }
  };

  const renderForm = () => (
    <Form onSubmit={selectedApikey ? handleUpdateApikey : handleAddApikey}>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form.Group className="mb-3">
        <Form.Label>Имя ключа</Form.Label>
        <Form.Control
          type="text"
          value={apiKeyName}
          onChange={(e) => setApiKeyName(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>API ключ</Form.Label>
        <Form.Control
          type="text"
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Check
          type="checkbox"
          label="Включен"
          checked={isActive}
          onChange={(e) => setIsActive(e.target.checked)}
        />
      </Form.Group>
      <Button
        variant="primary"
        type="submit"
        className="mb-3 me-3"
        disabled={!apiKeyName || !apiKey}
      >
        {selectedApikey ? "Обновить ключ" : "Добавить ключ"}
      </Button>
      {selectedApikey && (
        <Button
          variant="secondary"
          onClick={handleCancelEdit}
          className="mb-3 ml-2"
        >
          Отменить
        </Button>
      )}
    </Form>
  );

  const renderTable = () => (
    <Table>
      <tbody>
        {apikeys.map((apikey) => (
          <tr key={apikey.id}>
            <td>{apikey.key_name}</td>
            <td>{apikey.isActive ? "Включен" : "Выключен"}</td>
            <td className="text-end">
              <Button
                variant="primary"
                className="me-3"
                onClick={() => handleSelectApikey(apikey)}
              >
                Редактировать
              </Button>
              <Button
                variant="danger"
                className="me-3"
                onClick={() => handleDeleteApikey(apikey.id)}
              >
                Удалить
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  return (
    <>
      {renderForm()}
      {renderTable()}
    </>
  );
};

export default ApiKeySettings;
