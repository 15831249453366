import React from "react";
import LeftRightChat from "./Left&Right-Chat";

export default function ScrollBlock({ RefBodyChat, handleScroll, messages, tempMessages, SendMessage, chat, errorMessage, dismissError }) {
    return (
        <div onScroll={handleScroll} ref={RefBodyChat} className='Parent-Chats'>
            {errorMessage && (
                <div className='Error-Message'>
                    {errorMessage}
                    <button className="me-3 btn btn-danger" onClick={dismissError}>Закрыть</button>
                </div>
            )}
            <LeftRightChat messages={messages} tempMessages={tempMessages} chat={chat} SendMessage={SendMessage} />
        </div>
    );
}